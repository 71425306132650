export default {
  siteTitle: 'Get bonus',
  blogerName: 'WINGZ',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/wingz/about'
    },
    {
      name: 'telegram',
      url: 'https://t.me/+js2eHGg-nJ5lOGVh'
    },
    {
      name: 'telegram',
      url: 'https://t.me/+js2eHGg-nJ5lOGVh'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/bigwingz_/'
    },
    {
      name: 'discord',
      url: 'https://discord.com/invite/QhMQMdfHAY'
    },
    {
      name: 'twitter',
      url: 'https://twitter.com/lolwingz'
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c74ecade0',
      gameTitle: 'Burning Chilli X',
      casinoBonus1: '<strong>150%</strong><div>en el primer depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c7f1de3f5',
      gameTitle: 'Alien Fruits',
      casinoBonus1: '<strong>150%</strong><div>en el primer depósito</div>',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/cb3300655',
      gameTitle: 'Legzo Punk',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/ca4dd3ae4',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c90c68845',
      gameTitle: 'Izzi Art',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cae7b6234',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c2ac3fb33',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/ceca39b84',
      gameTitle: 'Jet Air',
    }
  ],
  headerText: 'Regístrate con mi código promocional <strong>WINGZ</strong> y obtén 50 giros gratis en los siguientes juegos',
  yourPromocode: 'Tu código promocional',
  gameBonus: 'Bono en el juego',
  promocode: 'WINGZ',
  casinoBonus1: '<strong>100% + 500FS</strong><div>en el primer depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado en el portapapeles',
  theme: 'dark',
};
